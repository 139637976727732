// import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import { Container, Table, Dropdown, Button, Form, Row, Col } from 'react-bootstrap';
// // import FeatherIcon from 'react-feather';

// function Attendance() {
//   const employees = [
//     { name: "Priyanka Dinodiya", designation: "Managing Director" },
//     { name: "Nikhil", designation: "Manager" },
//     { name: "Aman Maurya", designation: "Vice President" },
//     { name: "Vidhan Vikram Singh", designation: "Executive Director" },
//     { name: "Ankit Verma", designation: "General Manager" },
//     { name: "Mansi Jain", designation: "Manager" },
//     { name: "Shruti", designation: "Senior Executive" },
//   ];

//   return (
//     <Container fluid>
//       {/* Filter Section */}
//       <Row className="mb-3">
//         <Col>
//           <Dropdown>
//             <Dropdown.Toggle variant="outline-secondary">Employee: All</Dropdown.Toggle>
//             <Dropdown.Menu>
//               <Dropdown.Item>All</Dropdown.Item>
//               {/* Add more employee filter options if needed */}
//             </Dropdown.Menu>
//           </Dropdown>
//         </Col>
//         <Col>
//           <Dropdown>
//             <Dropdown.Toggle variant="outline-secondary">Department: All</Dropdown.Toggle>
//             <Dropdown.Menu>
//               <Dropdown.Item>All</Dropdown.Item>
//               {/* Add more department filter options if needed */}
//             </Dropdown.Menu>
//           </Dropdown>
//         </Col>
//         <Col>
//           <Dropdown>
//             <Dropdown.Toggle variant="outline-secondary">Designation: All</Dropdown.Toggle>
//             <Dropdown.Menu>
//               <Dropdown.Item>All</Dropdown.Item>
//               {/* Add more designation filter options if needed */}
//             </Dropdown.Menu>
//           </Dropdown>
//         </Col>
//         <Col>
//           <Dropdown>
//             <Dropdown.Toggle variant="outline-secondary">Month: November</Dropdown.Toggle>
//             <Dropdown.Menu>
//               <Dropdown.Item>November</Dropdown.Item>
//               {/* Add more month options */}
//             </Dropdown.Menu>
//           </Dropdown>
//         </Col>
//         <Col>
//           <Dropdown>
//             <Dropdown.Toggle variant="outline-secondary">Year: 2024</Dropdown.Toggle>
//             <Dropdown.Menu>
//               <Dropdown.Item>2024</Dropdown.Item>
//               {/* Add more year options */}
//             </Dropdown.Menu>
//           </Dropdown>
//         </Col>
//       </Row>

//       {/* Action Buttons */}
//       <Row className="mb-3">
//         <Col>
//           <Button variant="primary">+ Mark Attendance</Button>{' '}
//           <Button variant="outline-secondary">Import</Button>{' '}
//           <Button variant="outline-secondary">Export</Button>
//         </Col>
//       </Row>

//       {/* Table */}
//       <Table bordered hover>
//         <thead>
//           <tr>
//             <th>Employee</th>
//             {[...Array(30).keys()].map(day => (
//               <th key={day + 1}>{day + 1}</th>
//             ))}
//             <th>Total</th>
//           </tr>
//         </thead>
//         <tbody>
//           {employees.map((employee, index) => (
//             <tr key={index}>
//               <td>
//                 <div className="d-flex align-items-center">
//                   {/* Add profile image if needed */}
//                   <span style={{ marginLeft: '10px' }}>
//                     <strong>{employee.name}</strong><br />
//                     <small>{employee.designation}</small>
//                   </span>
//                 </div>
//               </td>
//               {[...Array(30).keys()].map(day => (
//                 <td key={day + 1}>
//                   <Form.Check type="checkbox" disabled /> {/* Dummy check */}
//                 </td>
//               ))}
//               <td>0 / 30</td>
//             </tr>
//           ))}
//         </tbody>
//       </Table>
//     </Container>
//   );
// }

// export default Attendance;



import React, { useState } from "react";
import {
  Table,
  Row,
  Col,
  Button,
  Dropdown,
  DropdownButton,
  ButtonGroup,
  Alert,
} from "react-bootstrap";
// import {
//   FeatherIcon,
//   FileText,
//   PlusCircle,
//   Users,
//   Calendar,
//   ArrowRightCircle,
//   Star,
//   AlertCircle,
//   X,
//   Airplay,
// } from "react-feather";

import FeatherIcon from "feather-icons-react";
import PageTitle from "../../../components/PageTitle";

const Attendance = () => {


  return (
    <>
        <PageTitle
        breadCrumbItems={[{ label: "Applicants", path: "/hr/attendance", active: true }]}
        title={"Attendance"}
      />

<Alert variant="info">
      <p>No check in </p>
    </Alert>
    </>
  );
};

export default Attendance;




