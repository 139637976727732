// import React, { useState } from 'react';
// import { Table, Form, Button, Dropdown } from 'react-bootstrap';
// import { useTable, usePagination, Column, CellProps } from 'react-table';
// import PageTitle from '../../../components/PageTitle';

// interface Employee {
//   id: number;
//   name: string;
//   email: string;
//   role: string;
//   reportingTo: string;
//   status: string;
//   image: string;
// }

// const EmployeeTable: React.FC = () => {
//   const [filter, setFilter] = useState<string>('');
//   const [statusFilter, setStatusFilter] = useState<string>('');

//   const data: Employee[] = React.useMemo(
//     () => [
//       { id: 1, name: 'John Doe', email: 'john@example.com', role: 'Software Engineer', reportingTo: 'Jane Smith', status: 'Active', image: 'https://via.placeholder.com/50' },
//       { id: 2, name: 'Jane Smith', email: 'jane@example.com', role: 'Product Manager', reportingTo: 'Bob Brown', status: 'Active', image: 'https://via.placeholder.com/50' },
//       { id: 3, name: 'Alice Johnson', email: 'alice@example.com', role: 'UX Designer', reportingTo: 'Frank Blue', status: 'Inactive', image: 'https://via.placeholder.com/50' },
//       { id: 4, name: 'Bob Brown', email: 'bob@example.com', role: 'QA Engineer', reportingTo: 'David Green', status: 'Active', image: 'https://via.placeholder.com/50' },
//       { id: 5, name: 'Charlie White', email: 'charlie@example.com', role: 'DevOps Engineer', reportingTo: 'Emily Black', status: 'Inactive', image: 'https://via.placeholder.com/50' },
//       { id: 6, name: 'David Green', email: 'david@example.com', role: 'Data Scientist', reportingTo: 'John Doe', status: 'Active', image: 'https://via.placeholder.com/50' },
//       { id: 7, name: 'Emily Black', email: 'emily@example.com', role: 'Marketing Manager', reportingTo: 'Jane Smith', status: 'Active', image: 'https://via.placeholder.com/50' },
//       { id: 8, name: 'Frank Blue', email: 'frank@example.com', role: 'System Administrator', reportingTo: 'Bob Brown', status: 'Inactive', image: 'https://via.placeholder.com/50' },
//     ],
//     []
//   );

//   const filteredData = React.useMemo(
//     () =>
//       data.filter(row =>
//         Object.values(row).some(value =>
//           String(value).toLowerCase().includes(filter.toLowerCase())
//         ) &&
//         (statusFilter ? row.status === statusFilter : true)
//       ),
//     [data, filter, statusFilter]
//   );

//   const columns: Column<Employee>[] = React.useMemo(
//     () => [
//       {
//         Header: 'Employee ID',
//         accessor: 'id',
//       },
//       {
//         Header: 'Name',
//         accessor: 'name',
//         Cell: ({ row }: CellProps<Employee>) => (
//           <div style={{ display: 'flex', alignItems: 'center' }}>
//             <img src={row.original.image} alt="Employee" style={{ width: '30px', borderRadius: '50%', marginRight: '8px' }} />
//             {row.original.name}
//           </div>
//         ),
//       },
//       { Header: 'Email', accessor: 'email' },
//       { Header: 'User Role', accessor: 'role' },
//       { Header: 'Reporting To', accessor: 'reportingTo' },
//       { Header: 'Status', accessor: 'status' },
//       {
//         Header: 'Action',
//         Cell: ({ row }: CellProps<Employee>) => (
//           <Dropdown>
//             <Dropdown.Toggle variant="outline-secondary" size="sm">
//               <i className="bi bi-three-dots-vertical"></i> {/* Three dots icon */}
//             </Dropdown.Toggle>

//             <Dropdown.Menu>
//               <Dropdown.Item onClick={() => alert(`View ${row.original.id}`)}>View</Dropdown.Item>
//               <Dropdown.Item onClick={() => alert(`Edit ${row.original.id}`)}>Edit</Dropdown.Item>
//               <Dropdown.Item onClick={() => alert(`Delete ${row.original.id}`)}>Delete</Dropdown.Item>
//             </Dropdown.Menu>
//           </Dropdown>
//         ),
//       },
//     ],
//     []
//   );

//   const {
//     getTableProps,
//     getTableBodyProps,
//     headerGroups,
//     page,
//     prepareRow,
//     canPreviousPage,
//     canNextPage,
//     pageOptions,
//     gotoPage,
//     nextPage,
//     previousPage,
//     setPageSize,
//     state: { pageIndex, pageSize },
//   } = useTable<Employee>(
//     {
//       columns,
//       data: filteredData,
//       initialState: { pageIndex: 0 },
//     },
//     usePagination
//   );

//   return (
//     <div className="container mb-4">
//       <PageTitle
//         breadCrumbItems={[{ label: "Employee", path: "/hr/employee", active: true }]}
//         title={"Employees"}
//       />
//       <div className="d-flex justify-content-between align-items-center mb-3">
//         <div className='d-flex align-items-center'>
//           <Form.Group controlId="search" className="me-2">
//             <Form.Label>Search:</Form.Label>
//             <Form.Control
//               type="text"
//               placeholder="Search by name, email, or role"
//               value={filter}
//               onChange={(e) => setFilter(e.target.value)}
//             />
//           </Form.Group>
//           <Form.Group controlId="statusFilter" className="mx-2">
//             <Form.Label>Status Filter:</Form.Label>
//             <Form.Select
//               value={statusFilter}
//               onChange={(e) => setStatusFilter((e.target as HTMLSelectElement).value)}
//             >
//               <option value="">All</option>
//               <option value="Active">Active</option>
//               <option value="Inactive">Inactive</option>
//             </Form.Select>
//           </Form.Group>
//         </div>
//         <div>
//           <Button variant="success">Add Employee</Button>
//         </div>
//       </div>
//       <Table {...getTableProps()} striped bordered hover className="mt-3">
//         <thead>
//           {headerGroups.map(headerGroup => (
//             <tr {...headerGroup.getHeaderGroupProps()}>
//               {headerGroup.headers.map(column => (
//                 <th {...column.getHeaderProps()}>{column.render('Header')}</th>
//               ))}
//             </tr>
//           ))}
//         </thead>
//         <tbody {...getTableBodyProps()}>
//           {page.length > 0 ? (
//             page.map(row => {
//               prepareRow(row);
//               return (
//                 <tr {...row.getRowProps()}>
//                   {row.cells.map(cell => (
//                     <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
//                   ))}
//                 </tr>
//               );
//             })
//           ) : (
//             <tr>
//               <td colSpan={columns.length} className="text-center">No results found</td>
//             </tr>
//           )}
//         </tbody>
//       </Table>
//       <div className="d-flex justify-content-between align-items-center mt-3">
//         <div>
//           <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage} variant="outline-secondary">
//             {'<<'}
//           </Button>{' '}
//           <Button onClick={() => previousPage()} disabled={!canPreviousPage} variant="outline-secondary">
//             {'<'}
//           </Button>{' '}
//           <Button onClick={() => nextPage()} disabled={!canNextPage} variant="outline-secondary">
//             {'>'}
//           </Button>{' '}
//           <Button onClick={() => gotoPage(pageOptions.length - 1)} disabled={!canNextPage} variant="outline-secondary">
//             {'>>'}
//           </Button>
//         </div>
//         <div>
//           Page{' '}
//           <strong>
//             {pageIndex + 1} of {pageOptions.length}
//           </strong>
//         </div>
//         <div>
//           <Form.Select
//             value={pageSize}
//             onChange={e => {
//               setPageSize(Number((e.target as HTMLSelectElement).value));
//             }}
//             style={{ width: 'auto' }}
//           >
//             {[5, 10, 20].map(size => (
//               <option key={size} value={size}>
//                 Show {size}
//               </option>
//             ))}
//           </Form.Select>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default EmployeeTable;



import React, { useState } from 'react';
import { Table, Form, Button, Dropdown } from 'react-bootstrap';
import { useTable, usePagination, Column, CellProps } from 'react-table';
import PageTitle from '../../../components/PageTitle';
import { Link } from 'react-router-dom';

interface Employee {
  id: number;
  name: string;
  email: string;
  role: string;
  reportingTo: string;
  status: string;
  department: string; // Added department
  image: string;
}

const EmployeeTable: React.FC = () => {
  const [filter, setFilter] = useState<string>('');
  const [statusFilter, setStatusFilter] = useState<string>('');
  const [departmentFilter, setDepartmentFilter] = useState<string>(''); // State for department filter

  const data: Employee[] = React.useMemo(
    () => [
      { id: 1, name: 'Nikhil', email: 'development@digitalipsum.in', role: 'Developer', reportingTo: 'Jane Smith', status: 'Active', department: 'Engineering', image: 'https://via.placeholder.com/50' },
      // { id: 2, name: 'Jane Smith', email: 'jane@example.com', role: 'Product Manager', reportingTo: 'Bob Brown', status: 'Active', department: 'Product', image: 'https://via.placeholder.com/50' },
      // { id: 3, name: 'Alice Johnson', email: 'alice@example.com', role: 'UX Designer', reportingTo: 'Frank Blue', status: 'Inactive', department: 'Design', image: 'https://via.placeholder.com/50' },
      // { id: 4, name: 'Bob Brown', email: 'bob@example.com', role: 'QA Engineer', reportingTo: 'David Green', status: 'Active', department: 'Quality Assurance', image: 'https://via.placeholder.com/50' },
      // { id: 5, name: 'Charlie White', email: 'charlie@example.com', role: 'DevOps Engineer', reportingTo: 'Emily Black', status: 'Inactive', department: 'Engineering', image: 'https://via.placeholder.com/50' },
      // { id: 6, name: 'David Green', email: 'david@example.com', role: 'Data Scientist', reportingTo: 'John Doe', status: 'Active', department: 'Data Science', image: 'https://via.placeholder.com/50' },
      // { id: 7, name: 'Emily Black', email: 'emily@example.com', role: 'Marketing Manager', reportingTo: 'Jane Smith', status: 'Active', department: 'Marketing', image: 'https://via.placeholder.com/50' },
      // { id: 8, name: 'Frank Blue', email: 'frank@example.com', role: 'System Administrator', reportingTo: 'Bob Brown', status: 'Inactive', department: 'IT', image: 'https://via.placeholder.com/50' },
    ],
    []
  );

  const filteredData = React.useMemo(
    () =>
      data.filter(row =>
        Object.values(row).some(value =>
          String(value).toLowerCase().includes(filter.toLowerCase())
        ) &&
        (statusFilter ? row.status === statusFilter : true) &&
        (departmentFilter ? row.department === departmentFilter : true) // Filter by department
      ),
    [data, filter, statusFilter, departmentFilter] // Added departmentFilter to dependencies
  );

  const columns: Column<Employee>[] = React.useMemo(
    () => [
      {
        Header: 'Employee ID',
        accessor: 'id',
      },
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({ row }: CellProps<Employee>) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src={row.original.image} alt="Employee" style={{ width: '30px', borderRadius: '50%', marginRight: '8px' }} />
            {row.original.name}
          </div>
        ),
      },
      { Header: 'Email', accessor: 'email' },
      { Header: 'User Role', accessor: 'role' },
      { Header: 'Reporting To', accessor: 'reportingTo' },
      { Header: 'Status', accessor: 'status' },
      {
        Header: 'Action',
        Cell: ({ row }: CellProps<Employee>) => (
          <Dropdown>
            <Dropdown.Toggle variant="outline-secondary" size="sm">
              <i className="bi bi-three-dots-vertical"></i>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => alert(`View ${row.original.id}`)}>View</Dropdown.Item>
              <Dropdown.Item onClick={() => alert(`Edit ${row.original.id}`)}>Edit</Dropdown.Item>
              <Dropdown.Item onClick={() => alert(`Delete ${row.original.id}`)}>Delete</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable<Employee>(
    {
      columns,
      data: filteredData,
      initialState: { pageIndex: 0 },
    },
    usePagination
  );

  return (
    <div className="mb-4">
      <PageTitle
        breadCrumbItems={[{ label: "Employee", path: "/hr/employee", active: true }]}
        title={"Employees"}
      />
      <div className="d-flex justify-content-between align-items-center mb-3 mt-2">
        <div className='d-flex align-items-center'>
          <Form.Group controlId="search" className="me-2">
            <Form.Label>Search:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Search by name, email, or role"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="statusFilter" className="mx-2">
            <Form.Label>Status Filter:</Form.Label>
            <Form.Select
              value={statusFilter}
              onChange={(e) => setStatusFilter((e.target as HTMLSelectElement).value)}
            >
              <option value="">All</option>
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </Form.Select>
          </Form.Group>
          <Form.Group controlId="departmentFilter" className="mx-2">
            <Form.Label>Department Filter:</Form.Label>
            <Form.Select
              value={departmentFilter}
              onChange={(e) => setDepartmentFilter((e.target as HTMLSelectElement).value)}
            >
              <option value="">All</option>
              <option value="Engineering">Engineering</option>
              <option value="Product">Product</option>
              <option value="Design">Design</option>
              <option value="Quality Assurance">Quality Assurance</option>
              <option value="Data Science">Data Science</option>
              <option value="Marketing">Marketing</option>
              <option value="IT">IT</option>
            </Form.Select>
          </Form.Group>
        </div>
        <div>
          <Link to="/hr/employees/create">
          <Button variant="primary">Add Employee</Button>
          </Link>
        </div>
      </div>
      <Table {...getTableProps()} striped bordered hover className="mt-3">
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.length > 0 ? (
            page.map(row => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  ))}
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={columns.length} className="text-center">No results found</td>
            </tr>
          )}
        </tbody>
      </Table>
      <div className="d-flex justify-content-between align-items-center mt-3">
        <div>
          <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage} variant="outline-secondary">
            {'<<'}
          </Button>{' '}
          <Button onClick={() => previousPage()} disabled={!canPreviousPage} variant="outline-secondary">
            {'<'}
          </Button>{' '}
          <Button onClick={() => nextPage()} disabled={!canNextPage} variant="outline-secondary">
            {'>'}
          </Button>{' '}
          <Button onClick={() => gotoPage(pageOptions.length - 1)} disabled={!canNextPage} variant="outline-secondary">
            {'>>'}
          </Button>
        </div>
        <div>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
        </div>
        <div>
          <Form.Select
            value={pageSize}
            onChange={e => {
              setPageSize(Number((e.target as HTMLSelectElement).value));
            }}
            style={{ width: 'auto' }}
          >
            {[5, 10, 20].map(size => (
              <option key={size} value={size}>
                Show {size}
              </option>
            ))}
          </Form.Select>
        </div>
      </div>
    </div>
  );
};

export default EmployeeTable;
