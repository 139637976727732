import React, { useRef } from "react";
import SignatureCanvas from "react-signature-canvas";
import { Button, Card } from "react-bootstrap";

// Define the props type
interface SignatureBoxProps {
  onSave: (dataURL: string) => void; // onSave function that takes a string
}

const SignatureBox: React.FC<SignatureBoxProps> = ({ onSave }) => {
  const signatureRef = useRef<SignatureCanvas>(null);

  const clear = () => {
    signatureRef.current?.clear(); // Optional chaining to handle null
  };

  const save = () => {
    const dataURL = signatureRef.current
      ?.getTrimmedCanvas()
      .toDataURL("image/png");
    if (dataURL) {
      onSave(dataURL); // Call onSave with the dataURL if it exists
    }
  };

  return (
    <div>
      <SignatureCanvas
        ref={signatureRef}
        canvasProps={{ className: "signature-canvas" }}
        //   style={{ border: '1px solid #ccc', borderRadius: '4px' }}
      />
      <div className="mt-3">
        <Button variant="secondary" onClick={clear} className="me-2">
          Clear
        </Button>
        <Button variant="primary" onClick={save}>
          Save Signature
        </Button>
      </div>
    </div>
  );
};

export default SignatureBox;
