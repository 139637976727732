import React, { useState } from "react";
import { Form, Button, Container, Row, Col, Card } from "react-bootstrap";
import DIImg from "../../../assets/images/diImg.png";
import Footer from "../../../layouts/Footer";
import SignatureBox from "../../../components/SignatureComponent";
import { useParams } from "react-router-dom";

interface JoiningFormInterface {
  full_name: string;
  email_address: string;
  telephone_number?: string;
  permanent_address?: string;
  current_address?: string;
  gender?: string;
  date_of_birth?: string;
  nationality?: string;
  marital_status?: string;
  emergency_contact_name?: string;
  emergency_relationship?: string;
  emergency_telephone?: string;
  emergency_email?: string;
  emergency_address?: string;
  parent_name?: string;
  parent_contact_number?: string;
  parent_job_profile?: string;
  parent_address?: string;
  education_school_university?: string;
  education_degree_diploma?: string;
  education_year_of_graduation?: string;
  previous_company?: string;
  previous_position?: string;
  previous_dates_of_employment?: string;
  previous_reason_for_leaving?: string;
  bank_name?: string;
  account_number?: string;
  account_holder_name?: string;
  bank_branch?: string;
  blood_group?: string;
  medical_conditions?: string;
  medical_insurance?: string;
  reference_name?: string;
  reference_relationship?: string;
  reference_telephone?: string;
  reference_email?: string;
  declaration_signature?: string;
  declaration_date?: string;
}

const JoiningForm: React.FC = () => {
  const [formData, setFormData] = useState<JoiningFormInterface>({
    full_name: "",
    email_address: "",
    telephone_number: "",
    permanent_address: "",
    current_address: "",
    gender: "",
    date_of_birth: "",
    nationality: "",
    marital_status: "",
    emergency_contact_name: "",
    emergency_relationship: "",
    emergency_telephone: "",
    emergency_email: "",
    emergency_address: "",
    parent_name: "",
    parent_contact_number: "",
    parent_job_profile: "",
    parent_address: "",
    education_school_university: "",
    education_degree_diploma: "",
    education_year_of_graduation: "",
    previous_company: "",
    previous_position: "",
    previous_dates_of_employment: "",
    previous_reason_for_leaving: "",
    bank_name: "",
    account_number: "",
    account_holder_name: "",
    bank_branch: "",
    blood_group: "",
    medical_conditions: "",
    medical_insurance: "",
    reference_name: "",
    reference_relationship: "",
    reference_telephone: "",
    reference_email: "",
    declaration_signature: "",
    declaration_date: "",
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [signature, setSignature] = useState<string>("");

  const handleSignatureSave = (dataURL: string) => {
    setSignature(dataURL);
  };

  const {name} = useParams();


  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    // const formSubmissionData = new FormData();
    // for (const key in formData) {
    //   const value = formData[key as keyof JoiningForm];
    //   if (value !== null && value !== undefined) {
    //     formSubmissionData.append(key, value);
    //   }
    // }

    const formDataSubmit = new FormData();
    for (const key in formData) {
      const value = formData[key as keyof JoiningFormInterface];
      if (value !== null && value !== undefined) {
        formDataSubmit.append(key, value);
      }
    }

    try {
      const response = await fetch(
        "http://localhost:4000/api/v1/hr/create-joining-form",
        {
          method: "POST",
          body: formDataSubmit,
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      console.log(result);
      // Handle success (e.g., show a success message)

      // Reset the form
      setFormData({
        full_name: "",
        email_address: "",
        telephone_number: "",
        permanent_address: "",
        current_address: "",
        gender: "",
        date_of_birth: "",
        nationality: "",
        marital_status: "",
        emergency_contact_name: "",
        emergency_relationship: "",
        emergency_telephone: "",
        emergency_email: "",
        emergency_address: "",
        parent_name: "",
        parent_contact_number: "",
        parent_job_profile: "",
        parent_address: "",
        education_school_university: "",
        education_degree_diploma: "",
        education_year_of_graduation: undefined,
        previous_company: "",
        previous_position: "",
        previous_dates_of_employment: "",
        previous_reason_for_leaving: "",
        bank_name: "",
        account_number: "",
        account_holder_name: "",
        bank_branch: "",
        blood_group: "",
        medical_conditions: "",
        medical_insurance: "",
        reference_name: "",
        reference_relationship: "",
        reference_telephone: "",
        reference_email: "",
        declaration_signature: "",
        declaration_date: "",
      });
    } catch (error) {
      console.error("Error submitting form:", error);
      // Handle error (e.g., show an error message)
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Container>
        <Card style={{ width: "100%", paddingBottom: "5px" }}>
          <Card.Img
            variant="top"
            src="https://images.pexels.com/photos/8235881/pexels-photo-8235881.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            style={{ height: "220px", objectFit: "cover" }}
          />
          <Card.Body>
            <Card.Img
              src={DIImg}
              style={{
                height: "100px",
                width: "100px",
                objectFit: "cover",
                position: "absolute",
                top: "45%",
                border: "1px solid #fff",
              }}
            />
            <Card.Title style={{ marginTop: "70px", fontSize: "1.7rem" }}>
              Joining Form
            </Card.Title>

            <Card.Text style={{ marginTop: "15px", fontSize: "1rem" }}>
              Please note: By submitting this form you acknowledge that you are
              not working elsewhere or freelancing at this time.
            </Card.Text>
          </Card.Body>
        </Card>

        <Card style={{ padding: "20px 10px" }}>
          <Card.Body>
            <form onSubmit={handleSubmit}>
              <h2
                style={{
                  marginTop: "10px",
                  marginBottom: "30px",
                  fontSize: "1.3rem",
                }}
              >
                Personal Information
              </h2>

              <Row>
                <Col md={4}>
                  <Form.Group controlId="formFullName" className="mb-3">
                    <Form.Label>Full Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="full_name"
                      value={formData.full_name}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group controlId="formGender" className="mb-3">
                    <Form.Label>Gender</Form.Label>
                    <Form.Select
                      name="gender"
                      value={formData.gender}
                      onChange={handleSelectChange}
                    >
                      <option value="">Select Gender</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Other">Other</option>
                    </Form.Select>
                  </Form.Group>
                </Col>

                <Col md={4}>
                  <Form.Group controlId="formDateOfBirth" className="mb-3">
                    <Form.Label>Date of Birth</Form.Label>
                    <Form.Control
                      type="date"
                      name="date_of_birth"
                      value={formData.date_of_birth}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={4}>
                  <Form.Group controlId="formNationality" className="mb-3">
                    <Form.Label>Nationality</Form.Label>
                    <Form.Control
                      type="text"
                      name="nationality"
                      value={formData.nationality}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>

                <Col md={4}>
                  <Form.Group controlId="formMaritalStatus" className="mb-3">
                    <Form.Label>Marital Status</Form.Label>
                    <Form.Select
                      name="marital_status"
                      value={formData.marital_status}
                      onChange={handleSelectChange}
                    >
                      <option value="">Select Marital Status</option>
                      <option value="Single">Single</option>
                      <option value="Married">Married</option>
                      <option value="Divorced">Divorced</option>
                      <option value="Widowed">Widowed</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>

              <h2
                style={{
                  marginTop: "30px",
                  marginBottom: "30px",
                  fontSize: "1.3rem",
                }}
              >
                Contact Information
              </h2>

              <Row>
                <Col md={4}>
                  <Form.Group controlId="formPermanentAddress" className="mb-3">
                    <Form.Label>Permanent Address</Form.Label>
                    <Form.Control
                      type="text"
                      name="permanent_address"
                      value={formData.permanent_address}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>

                <Col md={4}>
                  <Form.Group controlId="formCurrentAddress" className="mb-3">
                    <Form.Label>Current Address</Form.Label>
                    <Form.Control
                      type="text"
                      name="current_address"
                      value={formData.current_address}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group controlId="formEmail" className="mb-3">
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                      type="email"
                      name="email_address"
                      value={formData.email_address}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <Form.Group controlId="formPhoneNo" className="mb-3">
                    <Form.Label>Telephone Number</Form.Label>
                    <Form.Control
                      type="text"
                      name="telephone_number"
                      value={formData.telephone_number}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}></Col>
              </Row>

              <h2
                style={{
                  marginTop: "30px",
                  marginBottom: "30px",
                  fontSize: "1.3rem",
                }}
              >
                Emergency Contact Information
              </h2>

              <Row>
                <Col md={4}>
                  <Form.Group
                    controlId="formEmergencyRelationship"
                    className="mb-3"
                  >
                    <Form.Label>Emergency Relationship</Form.Label>
                    <Form.Control
                      type="text"
                      name="emergency_relationship"
                      value={formData.emergency_relationship}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group
                    controlId="formEmergencyContactName"
                    className="mb-3"
                  >
                    <Form.Label>Emergency Contact Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="emergency_contact_name"
                      value={formData.emergency_contact_name}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>

                <Col md={4}>
                  <Form.Group
                    controlId="formEmergencyTelephone"
                    className="mb-3"
                  >
                    <Form.Label>Emergency Contact Telephone</Form.Label>
                    <Form.Control
                      type="text"
                      name="emergency_telephone"
                      value={formData.emergency_telephone}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <Form.Group controlId="formEmergencyAddress" className="mb-3">
                    <Form.Label>Emergency Address</Form.Label>
                    <Form.Control
                      type="text"
                      name="emergency_address"
                      value={formData.emergency_address}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group controlId="formEmergencyEmail" className="mb-3">
                    <Form.Label>Emergency Contact Email</Form.Label>
                    <Form.Control
                      type="email"
                      name="emergency_email"
                      value={formData.emergency_email}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <h2
                style={{
                  marginTop: "30px",
                  marginBottom: "30px",
                  fontSize: "1.3rem",
                }}
              >
                Parent/ Guardian Information
              </h2>

              <Row>
                <Col md={4}>
                  <Form.Group controlId="formParentName" className="mb-3">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="parent_name"
                      value={formData.parent_name}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group controlId="formParentContact" className="mb-3">
                    <Form.Label>Contact Number</Form.Label>
                    <Form.Control
                      type="text"
                      name="parent_contact_number"
                      value={formData.parent_contact_number}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>

                <Col md={4}>
                  <Form.Group controlId="formParentJobProfile" className="mb-3">
                    <Form.Label>Job Profile</Form.Label>
                    <Form.Control
                      type="text"
                      name="parent_job_profile"
                      value={formData.parent_job_profile}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <Form.Group controlId="formParentAddress" className="mb-3">
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      type="text"
                      name="parent_address"
                      value={formData.parent_address}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <h2
                style={{
                  marginTop: "30px",
                  marginBottom: "30px",
                  fontSize: "1.3rem",
                }}
              >
                Education Information
              </h2>

              <Row>
                <Col md={4}>
                  <Form.Group
                    controlId="formEducationSchoolUniversity"
                    className="mb-3"
                  >
                    <Form.Label>School/University</Form.Label>
                    <Form.Control
                      type="text"
                      name="education_school_university"
                      value={formData.education_school_university}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group
                    controlId="formEducationDegreeDiploma"
                    className="mb-3"
                  >
                    <Form.Label>Degree/Diploma</Form.Label>
                    <Form.Control
                      type="text"
                      name="education_degree_diploma"
                      value={formData.education_degree_diploma}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>

                <Col md={4}>
                  <Form.Group
                    controlId="formEducationYearOfGraduation"
                    className="mb-3"
                  >
                    <Form.Label>Year of Graduation</Form.Label>
                    <Form.Control
                      type="number"
                      name="education_year_of_graduation"
                      value={formData.education_year_of_graduation}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <h2
                style={{
                  marginTop: "30px",
                  marginBottom: "30px",
                  fontSize: "1.3rem",
                }}
              >
                Previous Employment/ Internship Information
              </h2>

              <Row>
                <Col md={4}>
                  <Form.Group controlId="formPreviousCompany" className="mb-3">
                    <Form.Label>Previous Company</Form.Label>
                    <Form.Control
                      type="text"
                      name="previous_company"
                      value={formData.previous_company}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>

                <Col md={4}>
                  <Form.Group controlId="formPreviousPosition" className="mb-3">
                    <Form.Label>Previous Position</Form.Label>
                    <Form.Control
                      type="text"
                      name="previous_position"
                      value={formData.previous_position}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group
                    controlId="formPreviousDatesOfEmployment"
                    className="mb-3"
                  >
                    <Form.Label>Dates of Employment</Form.Label>
                    <Form.Control
                      type="date"
                      name="previous_dates_of_employment"
                      value={formData.previous_dates_of_employment}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={4}>
                  <Form.Group
                    controlId="formPreviousReasonForLeaving"
                    className="mb-3"
                  >
                    <Form.Label>Reason for Leaving</Form.Label>
                    <Form.Control
                      type="text"
                      name="previous_reason_for_leaving"
                      value={formData.previous_reason_for_leaving}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>


              {name === "paid" ? <>
                <h2
                style={{
                  marginTop: "30px",
                  marginBottom: "30px",
                  fontSize: "1.3rem",
                }}
              >
                Banking Information
              </h2>

              <Row>
                <Col md={4}>
                  <Form.Group controlId="formBankName" className="mb-3">
                    <Form.Label>Bank Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="bank_name"
                      value={formData.bank_name}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group controlId="formAccountNumber" className="mb-3">
                    <Form.Label>Account Number</Form.Label>
                    <Form.Control
                      type="text"
                      name="account_number"
                      value={formData.account_number}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group
                    controlId="formAccountHolderName"
                    className="mb-3"
                  >
                    <Form.Label>Account Holder Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="account_holder_name"
                      value={formData.account_holder_name}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={4}>
                  <Form.Group controlId="formBankBranch" className="mb-3">
                    <Form.Label>Bank Branch</Form.Label>
                    <Form.Control
                      type="text"
                      name="bank_branch"
                      value={formData.bank_branch}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              </> : ""}

              

              <h2
                style={{
                  marginTop: "30px",
                  marginBottom: "30px",
                  fontSize: "1.3rem",
                }}
              >
                Medical Information
              </h2>

              <Row>
                <Col md={4}>
                  <Form.Group controlId="formBloodGroup" className="mb-3">
                    <Form.Label>Blood Group</Form.Label>
                    <Form.Control
                      type="text"
                      name="blood_group"
                      value={formData.blood_group}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>

                <Col md={4}>
                  <Form.Group
                    controlId="formMedicalConditions"
                    className="mb-3"
                  >
                    <Form.Label>Medical Conditions</Form.Label>
                    <Form.Control
                      type="text"
                      name="medical_conditions"
                      value={formData.medical_conditions}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group controlId="formMedicalInsurance" className="mb-3">
                    <Form.Label>Medical Insurance</Form.Label>
                    <Form.Select
                      name="medical_insurance"
                      value={formData.medical_insurance}
                      onChange={handleSelectChange}
                    >
                      <option value="">Select</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>

              <h2
                style={{
                  marginTop: "30px",
                  marginBottom: "30px",
                  fontSize: "1.3rem",
                }}
              >
                Refrence Information
              </h2>

              <Row>
                <Col md={4}>
                  <Form.Group controlId="formReferenceName" className="mb-3">
                    <Form.Label>Reference Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="reference_name"
                      value={formData.reference_name}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group
                    controlId="formReferenceRelationship"
                    className="mb-3"
                  >
                    <Form.Label>Reference Relationship</Form.Label>
                    <Form.Control
                      type="text"
                      name="reference_relationship"
                      value={formData.reference_relationship}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group
                    controlId="formReferenceTelephone"
                    className="mb-3"
                  >
                    <Form.Label>Reference Telephone</Form.Label>
                    <Form.Control
                      type="text"
                      name="reference_telephone"
                      value={formData.reference_telephone}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <Form.Group controlId="formReferenceEmail" className="mb-3">
                    <Form.Label>Reference Email</Form.Label>
                    <Form.Control
                      type="email"
                      name="reference_email"
                      value={formData.reference_email}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Card.Text
                style={{
                  marginTop: "35px",
                  marginBottom: "34px",
                  fontSize: "1.2rem",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                I hereby confirm that all the above information is accurate to
                the best of my knowledge.
              </Card.Text>

              <Row>
                <Form.Group controlId="formDeclarationDate" className="mb-4">
                  <Form.Label>Declaration Date</Form.Label>
                  <Form.Control
                    type="date"
                    name="declaration_date"
                    value={formData.declaration_date}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Row>

              <Row>
                <Col md={6}>
                  <Form.Group
                    controlId="formDeclarationSignature"
                    className="mb-3"
                  >
                    <Form.Label>Declaration Signature</Form.Label>
                    <SignatureBox onSave={handleSignatureSave} />
                  </Form.Group>
                </Col>

                <Col md={6}>
                  <Form.Group
                    controlId="formDeclarationSignature"
                    className="mb-3"
                  >
                    <Form.Label>Saved Signature</Form.Label>
                    <div>
                      {signature ? <><img src={signature} alt="Signature" /></> : ""}
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
              <Button variant="primary" type="submit" disabled={loading}>
                {loading ? "Submitting..." : "Submit"}
              </Button>

              <Button variant="danger">
                {"Cancel"}
              </Button>
              </div>
              


            </form>
          </Card.Body>
        </Card>
        {/* <Footer /> */}
      </Container>
      
    </>
  );
};

export default JoiningForm;
