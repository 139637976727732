import React from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import PageTitle from "../../../components/PageTitle";

const CreateEmployee: React.FC = () => {
  return (
    <div>
        <div className="mb-2">
        <PageTitle
        breadCrumbItems={[{ label: "Add Employee", path: "/hr/employee/create", active: true }]}
        title={"Add Employee"}
      />
        </div>
    
    <Form>
      <Row>
        <Col md={6}>
          <Form.Group controlId="formEmployeeID" className="mb-3">
            <Form.Label>Employee ID</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Employee ID"
              required
            />
          </Form.Group>

          <Form.Group controlId="formSalutation" className="mb-3">
            <Form.Label>Salutation</Form.Label>
            <Form.Control as="select" defaultValue="Choose...">
              <option>Mr.</option>
              <option>Ms.</option>
              <option>Mrs.</option>
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="formEmployeeName" className="mb-3">
            <Form.Label>Employee Name</Form.Label>
            <Form.Control type="text" placeholder="e.g. John Doe" required />
          </Form.Group>

          <Form.Group controlId="formEmployeeEmail" className="mb-3">
            <Form.Label>Employee Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="e.g. johndoe@example.com"
              required
            />
          </Form.Group>

          <Form.Group controlId="formPassword" className="mb-3">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Must have at least 8 characters"
              required
            />
          </Form.Group>

          <Form.Group controlId="formDesignation" className="mb-3">
            <Form.Label>Designation</Form.Label>
            <Form.Control type="text" placeholder="Enter Designation" />
          </Form.Group>

          <Form.Group controlId="formDepartment" className="mb-3">
            <Form.Label>Department</Form.Label>
            <Form.Control type="text" placeholder="Enter Department" />
          </Form.Group>
        </Col>

        <Col md={6}>
          <Form.Group controlId="formProfilePicture" className="mb-3">
            <Form.Label>Profile Picture</Form.Label>
            <Form.Control type="file" />
          </Form.Group>

          <Form.Group controlId="formCountry" className="mb-3">
            <Form.Label>Country</Form.Label>
            <Form.Control type="text" placeholder="Enter Country" />
          </Form.Group>

          <Form.Group controlId="formMobile" className="mb-3">
            <Form.Label>Mobile</Form.Label>
            <Form.Control type="text" placeholder="e.g. 1234567890" />
          </Form.Group>

          <Form.Group controlId="formGender" className="mb-3">
            <Form.Label>Gender</Form.Label>
            <Form.Control as="select" defaultValue="Choose...">
              <option>Male</option>
              <option>Female</option>
              <option>Other</option>
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="formJoiningDate" className="mb-3">
            <Form.Label>Joining Date</Form.Label>
            <Form.Control type="date" required />
          </Form.Group>

          <Form.Group controlId="formDateOfBirth" className="mb-3">
            <Form.Label>Date of Birth</Form.Label>
            <Form.Control type="date" />
          </Form.Group>

          <Form.Group controlId="formReportingTo" className="mb-3">
            <Form.Label>Reporting To</Form.Label>
            <Form.Control type="text" placeholder="Enter Reporting Person" />
          </Form.Group>

          <Form.Group controlId="formLanguage" className="mb-3">
            <Form.Label>Language</Form.Label>
            <Form.Control type="text" placeholder="Enter Language" />
          </Form.Group>
        </Col>

        <Col md={6}>
          <Form.Group controlId="formUserRole" className="mb-3">
            <Form.Label>User Role</Form.Label>
            <Form.Control type="text" placeholder="Enter User Role" />
          </Form.Group>

          <Form.Group controlId="formAddress" className="mb-3">
            <Form.Label>Address</Form.Label>
            <Form.Control
              type="text"
              placeholder="e.g. 132, My Street, Kingston, NY 12401"
            />
          </Form.Group>

          <Form.Group controlId="formAbout" className="mb-3">
            <Form.Label>About</Form.Label>
            <Form.Control as="textarea" rows={3} />
          </Form.Group>

          <Form.Group controlId="formOtherDetails" className="mb-3">
            <Form.Label>Other Details</Form.Label>
            <Form.Control as="textarea" rows={3} />
          </Form.Group>

          <Form.Group controlId="formLoginAllowed" className="mb-3">
            <Form.Label>Login Allowed?</Form.Label>
            <Form.Check
              type="radio"
              label="Yes"
              name="loginAllowed"
              id="loginAllowedYes"
            />
            <Form.Check
              type="radio"
              label="No"
              name="loginAllowed"
              id="loginAllowedNo"
            />
          </Form.Group>

          <Form.Group controlId="formReceiveNotifications" className="mb-3">
            <Form.Label>Receive email notifications?</Form.Label>
            <Form.Check
              type="radio"
              label="Yes"
              name="receiveNotifications"
              id="receiveNotificationsYes"
            />
            <Form.Check
              type="radio"
              label="No"
              name="receiveNotifications"
              id="receiveNotificationsNo"
            />
          </Form.Group>

          <Form.Group controlId="formHourlyRate" className="mb-3">
            <Form.Label>Hourly Rate</Form.Label>
            <Form.Control type="number" placeholder="₹" />
          </Form.Group>

          <Form.Group controlId="formSlackMemberID" className="mb-3">
            <Form.Label>Slack Member ID</Form.Label>
            <Form.Control type="text" placeholder="@" />
          </Form.Group>
        </Col>

        <Col md={6}>
          <Form.Group controlId="formSkills" className="mb-3">
            <Form.Label>Skills</Form.Label>
            <Form.Control type="text" placeholder="Enter Skills" />
          </Form.Group>

          <Form.Group controlId="formProbationEndDate" className="mb-3">
            <Form.Label>Probation End Date</Form.Label>
            <Form.Control type="date" />
          </Form.Group>

          <Form.Group controlId="formNoticePeriodStartDate" className="mb-3">
            <Form.Label>Notice Period Start Date</Form.Label>
            <Form.Control type="date" />
          </Form.Group>

          <Form.Group controlId="formNoticePeriodEndDate" className="mb-3">
            <Form.Label>Notice Period End Date</Form.Label>
            <Form.Control type="date" />
          </Form.Group>

          <Form.Group controlId="formEmploymentType" className="mb-3">
            <Form.Label>Employment Type</Form.Label>
            <Form.Control type="text" placeholder="Enter Employment Type" />
          </Form.Group>

          <Form.Group controlId="formMaritalStatus" className="mb-3">
            <Form.Label>Marital Status</Form.Label>
            <Form.Control as="select" defaultValue="Choose...">
              <option>Single</option>
              <option>Married</option>
              <option>Divorced</option>
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="formBusinessAddress" className="mb-3">
            <Form.Label>Business Address</Form.Label>
            <Form.Control type="text" placeholder="Enter Business Address" />
          </Form.Group>
        </Col>
        <div className="d-flex justify-content-center mt-2 mb-4 gap-2">
          
        <Button variant="danger" type="submit">
            Cancel
          </Button>
          <Button variant="primary" type="submit">
            Submit
          </Button>
          
        </div>
      </Row>
    </Form>
    </div>
  );
};

export default CreateEmployee;
