

import React, { useState } from "react";
import { Form, Button, Container, Row, Col, Card } from "react-bootstrap";
import DIImg from "../../../assets/images/diImg.png";
import Footer from "../../../layouts/Footer";

interface Applicant {
  name: string;
  email: string;
  phone_no?: string;
  address?: string;
  department?: string;
  total_exp?: string;
  current_ctc?: string;
  expected_ctc?: string;
  ctc_rate?: string;
  cv?: File | null;
  cover_letter?: string;
  portfolio?: File | null;
}

const ApplicantForm: React.FC = () => {
  const [applicant, setApplicant] = useState<Applicant>({
    name: "",
    email: "",
    phone_no: "",
    address: "",
    department: "",
    total_exp: "",
    current_ctc: "",
    expected_ctc: "",
    ctc_rate: "",
    cv: null,
    cover_letter: "",
    portfolio: null,
  });

  const [loading, setLoading] = useState<boolean>(false); // Loading state

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setApplicant({
      ...applicant,
      [name]: value,
    });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setApplicant({
      ...applicant,
      [name]: value,
    });
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, files } = e.target;
    if (files && files[0]) {
      setApplicant({
        ...applicant,
        [name]: files[0],
      });
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true); // Set loading to true

    const formData = new FormData();
    for (const key in applicant) {
      const value = applicant[key as keyof Applicant];
      if (value !== null && value !== undefined) {
        formData.append(key, value);
      }
    }

    try {
      const response = await fetch("http://localhost:4000/api/v1/hr/create-applicant", {
        method: "POST",
        body: formData, // Use formData instead of JSON.stringify
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      console.log(result);
      // Handle success (e.g., show a success message)

      // Reset the form
      setApplicant({
        name: "",
        email: "",
        phone_no: "",
        address: "",
        department: "",
        total_exp: "",
        current_ctc: "",
        expected_ctc: "",
        ctc_rate: "",
        cv: null,
        cover_letter: "",
        portfolio: null,
      });
    } catch (error) {
      console.error("Error submitting form:", error);
      // Handle error (e.g., show an error message)
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  return (
    <>
      <Container>
        <Card style={{ width: "100%", paddingBottom: "5px" }}>
          <Card.Img
            variant="top"
            src="https://images.pexels.com/photos/8235881/pexels-photo-8235881.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            style={{ height: "220px", objectFit: "cover" }}
          />
          <Card.Body>
            <Card.Img
              src={DIImg}
              style={{
                height: "100px",
                width: "100px",
                objectFit: "cover",
                position: "absolute",
                top: "45%",
                border: "1px solid #fff",
              }}
            />
            <Card.Title style={{ marginTop: "70px", fontSize: "1.6rem" }}>
              Applicants Form
            </Card.Title>
          </Card.Body>
        </Card>

        <Card style={{ padding: "20px 10px" }}>
          <Card.Body>
            <form onSubmit={handleSubmit}>
              <Row>
                <Col md={6}>
                  <Form.Group controlId="formName" className="mb-3">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      value={applicant.name}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="formEmail" className="mb-3">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      value={applicant.email}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group controlId="formPhoneNo" className="mb-3">
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                      type="text"
                      name="phone_no"
                      value={applicant.phone_no}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="formAddress" className="mb-3">
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      type="text"
                      name="address"
                      value={applicant.address}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group controlId="formDepartment" className="mb-3">
                    <Form.Label>Total Experience</Form.Label>
                    <Form.Select name="total_exp" value={applicant.total_exp} onChange={handleSelectChange}>
                      <option value="fresher">Fresher</option>
                      <option value="1-2">1-2</option>
                      <option value="2-3">2-3</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="formCTC" className="mb-3">
                    <Form.Label>Current CTC</Form.Label>
                    <Form.Control
                      type="number"
                      name="current_ctc"
                      value={applicant.current_ctc}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group controlId="formCTCRate" className="mb-3">
                    <Form.Label>Current CTC Rate</Form.Label>
                    <Form.Select name="ctc_rate" value={applicant.ctc_rate} onChange={handleSelectChange}>
                      <option value="per hour">per Hour</option>
                      <option value="per month">per Month</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="formExpectedCTC" className="mb-3">
                    <Form.Label>Expected CTC</Form.Label>
                    <Form.Control
                      type="number"
                      name="expected_ctc"
                      value={applicant.expected_ctc}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group controlId="formDepartment" className="mb-3">
                    <Form.Label>Department</Form.Label>
                    <Form.Select name="department" value={applicant.department} onChange={handleSelectChange}>
                      <option>Open this select menu</option>
                      <option value="Graphics">Graphics</option>
                      <option value="Web">Web</option>
                      <option value="Content">Content</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="formCv" className="mb-3">
                    <Form.Label>CV</Form.Label>
                    <Form.Control
                      type="file"
                      name="cv"
                      onChange={handleFileChange}
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group controlId="formCoverLetter" className="mb-3">
                    <Form.Label>Cover Letter</Form.Label>
                    <Form.Control as="textarea" rows={3} name="cover_letter" onChange={handleChange} value={applicant.cover_letter} />
                  </Form.Group>
                </Col>
                {applicant.department === "Graphics" && (
                  <Col md={6}>
                    <Form.Group controlId="formPortfolio" className="mb-3">
                      <Form.Label>Portfolio</Form.Label>
                      <Form.Control
                        type="file"
                        name="portfolio"
                        onChange={handleFileChange}
                      />
                    </Form.Group>
                  </Col>
                )}
              </Row>

              <hr />

              <Card.Title style={{ marginTop: "40px", fontSize: "1.4rem" }}>
                Terms And Condition
              </Card.Title>

              <Card.Text
                style={{ marginTop: "30px", fontSize: "1rem", lineHeight: "1.7" }}
              >
                {/* Your terms and conditions text here */}
              </Card.Text>

              <Form.Check
                inline
                label="I Agree with the above Terms and conditions"
                name="group1"
              />

              <hr />

              <div className="mt-4 d-flex gap-2">
                <Button variant="primary" type="submit" disabled={loading}>
                  {loading ? "Submitting..." : "Apply"}
                </Button>
                <Button variant="danger">Cancel</Button>
              </div>
            </form>
          </Card.Body>
        </Card>
      </Container>
      <div className="mt-5">
        <Footer />
      </div>
    </>
  );
};

export default ApplicantForm;

